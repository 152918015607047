import api from '@/api/collections'
import { findIndex } from 'lodash'

const namespaced = true

const state = {
  collections: [],
  mimeTypes: [
    {
      group: 'Image',
      mimes: [
        'jpg', 'png', 'gif', 'tif', 'bmp', 'ico', 'heic'
      ]
    },
    {
      group: 'Video',
      mimes: [
        'mp4', 'm4v', 'mkv', 'webm', 'mov', 'avi', 'wmv', 'mpg', 'flv'
      ]
    },
    {
      group: 'Audio',
      mimes: [
        'mp3', 'm4a', 'ogg', 'flac', 'wav'
      ]
    },
    {
      group: 'Document',
      mimes: [
        'pdf', 'psd', 'docx', 'doc', 'msi', 'pptx', 'xlsx', 'xls', 'ppt', 'xml'
      ]
    },
    {
      group: 'Archive',
      mimes: [
        'zip', 'tar', 'rar', 'gz', 'bz2', '7z', 'dmg'
      ]
    }
  ]
}

// getters
const getters = {
  list: state => {
    return state.collections
  },
  mimes: state => {
    return state.mimeTypes
  }
}

// actions
const actions = {
  refresh({ commit }) {
    api.listCollections(collections => {
      commit('setCollections', collections)
    })
  },
  add({ commit }, payload) {
    commit('addCollection', payload);
  },
  set({ commit }, payload) {
    commit('setCollections', payload);
  },
  update({ commit }, payload) {
    commit('updateCollection', payload);
  },
  remove({ commit }, payload) {
    commit('removeCollections', payload);
  },
}

// mutations
const mutations = {
  setCollections(state, payload) {
    state.collections = payload;
  },
  addCollection(state, payload) {
    state.collections = [...state.collections, payload]
  },
  updateCollection(state, payload) {
    let index = findIndex(state.collections, { slug: payload.slug });
    state.collections.splice(index, 1, payload);
  },
  removeCollections(state, payload) {
    let index = findIndex(state.collections, { slug: payload });
    state.collections.splice(index, 1);
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
