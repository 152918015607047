import App from '@/App.vue'
import axios from 'axios'

export default {
  listCollections(cb) {
    axios.get('collections/list')
      .then(response => cb(response.data.collections))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
