import Vue from 'vue';
import Moment from 'moment';

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
});

Vue.filter('formatLength', function (length, leadingZero = true) {
  let time = Moment().startOf('day').seconds(length);
  let string = '';
  if (parseInt(time.format('H')) !== 0) {
    string += time.format('H') + 'h ';
  }
  if (parseInt(time.format('m')) !== 0) {
    string += (leadingZero ? time.format('mm') : time.format('m')) + 'm ';
  }
  string += (leadingZero ? time.format('ss') : time.format('s')) + 's';
  return string;
});
