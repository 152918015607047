<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Your Notifications</span>
      <el-button type="text" @click="$modal.hide('notifications-modal')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <notification-table></notification-table>
  </el-card>
</template>

<style scoped>
</style>

<script>
import NotificationTable from "@/components/Tables/NotificationTable"

export default {
  name: "NotificationsList",

  components: {
    NotificationTable,
  },
}
</script>
