import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAsterisk,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faBars,
  faBox,
  faBoxes,
  faBuilding,
  faCaretDown,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCloudUploadAlt,
  faCogs,
  faComments,
  faDatabase,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExclamation,
  faExclamationCircle,
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAudio,
  faFileAlt,
  faFileArchive,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFileVideo,
  faFileUpload,
  faGlobe,
  faHeadphones,
  faImages,
  faInfoCircle,
  faLink,
  faLock,
  faLockOpen,
  faPaintBrush,
  faPause,
  faPauseCircle,
  faPencilAlt,
  faPlay,
  faPlayCircle,
  faPlus,
  faQuestionCircle,
  faShareSquare,
  faSignOutAlt,
  faSlidersH,
  faSort,
  faSpinner,
  faStopCircle,
  faSyncAlt,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUserCircle,
  faUsers,
  faVideo,
  faWindowClose,
  faList,
  faGripHorizontal
} from '@fortawesome/free-solid-svg-icons'

import {
  faLifeRing
} from '@fortawesome/free-regular-svg-icons'


import { faFacebookF, faGoogle, faMicrosoft, faVimeo } from '@fortawesome/free-brands-svg-icons'

library.add(faAsterisk)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faArrowsAltH)
library.add(faAngleRight)
library.add(faBars)
library.add(faBox)
library.add(faBoxes)
library.add(faBuilding)
library.add(faCaretDown)
library.add(faCircle)
library.add(faChartLine)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCloudUploadAlt)
library.add(faDatabase)
library.add(faDownload)
library.add(faCogs)
library.add(faComments)
library.add(faEnvelope)
library.add(faEnvelopeOpen)
library.add(faEllipsisH)
library.add(faEllipsisV)
library.add(faExclamation)
library.add(faExclamationCircle)
library.add(faExternalLinkSquareAlt)
library.add(faEye)
library.add(faEyeSlash)
library.add(faFile)
library.add(faFileAudio)
library.add(faFileAlt)
library.add(faFileArchive)
library.add(faFileExcel)
library.add(faFileImage)
library.add(faFilePdf)
library.add(faFilePowerpoint)
library.add(faFileWord)
library.add(faFileVideo)
library.add(faFileUpload)
library.add(faHeadphones)
library.add(faGlobe)
library.add(faLink)
library.add(faLock)
library.add(faLockOpen)
library.add(faInfoCircle)
library.add(faImages)
library.add(faPaintBrush)
library.add(faPause)
library.add(faPauseCircle)
library.add(faPencilAlt)
library.add(faPlay)
library.add(faPlayCircle)
library.add(faPlus)
library.add(faQuestionCircle)
library.add(faShareSquare)
library.add(faSignOutAlt)
library.add(faSlidersH)
library.add(faSpinner)
library.add(faStopCircle)
library.add(faSort)
library.add(faSyncAlt)
library.add(faSync)
library.add(faTimes)
library.add(faTimesCircle)
library.add(faTrash)
library.add(faTrashAlt)
library.add(faUserCircle)
library.add(faUpload)
library.add(faUser)
library.add(faUsers)
library.add(faWindowClose)
library.add(faVideo)

library.add(faList)
library.add(faGripHorizontal)

library.add(faLifeRing)

library.add(faGoogle)
library.add(faFacebookF)
library.add(faMicrosoft)
library.add(faVimeo)

Vue.component('font-awesome-icon', FontAwesomeIcon)
