<template>
  <div v-on-clickaway="away">
    <slot></slot>
  </div>
</template>

<style scoped>
</style>

<script>
import { mixin as clickaway } from "vue-clickaway"

export default {
  name: "ModalSelect",

  mixins: [clickaway],
  components: {},

  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},

  methods: {
    away() {
      if (this.$slots.default[0].componentInstance.visible) {
        this.$slots.default[0].componentInstance.blur()
      }
    },
  },

  created() {},
  mounted() {},
}
</script>
