import api from '@/api/tracker'
import store from '@/store'

const namespaced = true

const state = {
  admin: null,
  supermetricsCounts: null
}

// getters
const getters = {
  currentAdmin: state => {
    return state.admin
  },
  supermetricsCounts: state => {
    return state.supermetricsCounts
  },
}

// actions
const actions = {
  getAdmin({ commit }) {
    state.admin = null;
    api.getCompanyAdmin(data => {
      commit('setAdmin', data.data)
      commit('setSupermetricsCounts', data.supermetrics_counts)
    }, store.state.route.params.company)
  },
  updateAdmin({ commit }, company) {
    state.admin = null;
    commit('setAdmin', company)
  }
}

// mutations
const mutations = {
  setAdmin(state, payload) {
    state.admin = payload;
  },
  setSupermetricsCounts(state, payload) {
    state.supermetricsCounts = payload;
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
