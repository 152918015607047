import App from '@/App.vue'
import axios from 'axios'

export default {
  listCampaigns(company, date, cb) {
    axios.get('campaigns/' + company + '/' + date + '/list')
      .then(response => cb(response.data.campaigns))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  },

  trackerListCampaigns(company, page, service, query, cb) {
    axios.get('tracker/' + company + '/campaigns/list', { params: { page: page, query: query, service: service } })
      .then(response => cb(response.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  },

  async trackerListServices(company, cb) {
    await axios.get('tracker/' + company + '/campaigns/services/list')
      .then(response => cb(response.data.services))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
