<template>
  <div>
    <div class="breadcrumb-item">
      <router-link
        v-if="company"
        :to="{ name: 'company', params: { company: company.slug } }"
      >
        Home
      </router-link>
    </div>
    <div class="breadcrumb-sep">
      <font-awesome-icon icon="angle-right" full-width></font-awesome-icon>
    </div>
    <div
      class="breadcrumb-item"
      v-if="
        (company && $_.size(company.months) > 1) ||
        $auth.check(['super', 'admin', 'employee'])
      "
    >
      <el-popover
        placement="bottom-start"
        width="250"
        trigger="click"
        ref="monthPickerPopover"
        @show="pickerActive = true"
        @hide="pickerActive = false"
      >
        <breadcrumb-picker
          :existing="month"
          v-if="pickerActive"
          @input="goToMonth"
          :company="company"
        ></breadcrumb-picker>
        <span slot="reference" style="cursor: pointer">
          {{ formattedMonth }}
          <font-awesome-icon
            icon="caret-down"
            style="margin-left: 0.25rem; cursor: pointer"
          ></font-awesome-icon>
        </span>
      </el-popover>
    </div>
    <div class="breadcrumb-item" v-else>
      {{ formattedMonth }}
    </div>
    <div class="breadcrumb-sep" v-if="campaigns && campaigns.length > 1">
      <font-awesome-icon icon="angle-right" full-width></font-awesome-icon>
    </div>
    <div
      class="breadcrumb-item"
      v-if="campaigns && campaigns.length > 1"
      style="cursor: pointer"
    >
      <el-dropdown
        placement="bottom-start"
        size="small"
        trigger="click"
        @command="goToCampaign"
      >
        <span class="el-dropdown-link">
          {{ formattedCampaign }}
          <font-awesome-icon
            icon="caret-down"
            style="margin-left: 0.25rem"
          ></font-awesome-icon>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="campaigns">
          <el-dropdown-item :command="null"> All Campaigns </el-dropdown-item>
          <el-dropdown-item
            v-for="campaign in campaigns"
            :key="campaign.slug"
            :command="campaign.slug"
          >
            {{ campaign.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { mapGetters } from "vuex"
import moment from "moment-timezone"
import BreadcrumbPicker from "@/components/BreadcrumbPicker"

export default {
  name: "MonthBreadcrumbs",

  mixins: [],
  components: { BreadcrumbPicker },

  props: {},
  data() {
    return {
      month: null,
      route: {},
      pickerActive: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
      campaigns: "campaigns/list",
    }),
    formattedMonth() {
      if (this.$route.params.month === "evergreen") return "Evergreen"
      return moment(this.$route.params.month, "MM-YYYY").format("MMM YYYY")
    },
    formattedCampaign() {
      if (!this.$route.params.campaign) return "All Campaigns"
      let item = this.$_.find(
        this.campaigns,
        (o) => o.slug === this.$route.params.campaign
      )
      return item ? item.name : ""
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
  },

  methods: {
    routeChange() {
      this.route = this.$route
      this.month = this.$route.params.month
    },
    goToMonth(value) {
      this.$nextTick(function () {
        this.$refs.monthPickerPopover.doClose()
      })
      this.$router.push({
        name: "month",
        params: {
          company: this.$route.params.company,
          month: value,
        },
      })
    },
    goToCampaign(value) {
      if (value === null) {
        this.$router.push({
          name: "month",
          params: {
            company: this.$route.params.company,
            month: this.$route.params.month,
          },
        })
      } else {
        this.$router.push({
          name: "month",
          params: {
            company: this.$route.params.company,
            month: this.$route.params.month,
            campaign: value,
          },
        })
      }
    },
  },

  created() {},
  mounted() {
    this.routeChange()
  },
}
</script>
