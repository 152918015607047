<template>
  <div class="breadcrumb-container">
    <template v-if="$route.name === 'month'">
      <month-breadcrumbs></month-breadcrumbs>
    </template>
    <template v-if="$route.name === 'tracker' && company.tracker">
      <tracker-breadcrumbs></tracker-breadcrumbs>
    </template>
  </div>
</template>

<style lang="scss">
.breadcrumb-item,
.breadcrumb-sep {
  padding: 0 0.25rem;
}

.month-picker-input {
  width: 65px !important;
  cursor: pointer;

  .el-input__inner {
    border: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    font-size: 14px !important;
    line-height: 20px;
  }
  .el-input__prefix {
    display: none;
  }
}

.no-link {
  cursor: default;
}

.month-picker {
  width: 200px !important;

  .el-picker-panel__content,
  .el-date-picker__header {
    width: 200px !important;
    margin-left: 0;
  }

  .el-date-picker__header {
    margin-top: 5px;
    padding-bottom: 5px;
  }

  .el-month-table td {
    text-align: center;
    padding: 5px;
    cursor: pointer;

    .cell {
      width: 30px;
      height: 30px;
    }
  }

  .el-month-table td.disabled {
    .cell {
      background: transparent;
    }
  }
}
</style>

<script>
import moment from "moment"

import { mapGetters } from "vuex"
import TrackerBreadcrumbs from "@/components/TrackerBreadcrumbs"
import MonthBreadcrumbs from "@/components/MonthBreadcrumbs"

export default {
  name: "Breadcrumbs",
  components: { TrackerBreadcrumbs, MonthBreadcrumbs },

  data() {
    return {
      month: "",
      route: {},
      pickerOptions: {
        disabledDate: (date) => {
          if (this.$auth.check(["super", "admin", "employee"])) return false
          return (
            this.$_.find(
              this.company.months,
              (o) => o === moment(date).format("MM-YYYY")
            ) === undefined
          )
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
      campaigns: "campaigns/list",
    }),
    formattedMonth() {
      return moment(this.route.params.month, "MM-YYYY").format("MMM YYYY")
    },
    formattedCampaign() {
      if (!this.$route.params.campaign) return "All Campaigns"
      let item = this.$_.find(
        this.campaigns,
        (o) => o.slug === this.$route.params.campaign
      )
      return item ? item.name : ""
    },
  },
  watch: {
    $route() {
      this.route = this.$route
      this.month = this.route.params.month
    },
  },

  methods: {
    goToMonth(value) {
      this.$router.push({
        name: "month",
        params: {
          company: this.$route.params.company,
          month: value,
        },
      })
    },
    goToCampaign(value) {
      if (value === null) {
        this.$router.push({
          name: "month",
          params: {
            company: this.$route.params.company,
            month: this.$route.params.month,
          },
        })
      } else {
        this.$router.push({
          name: "month",
          params: {
            company: this.$route.params.company,
            month: this.$route.params.month,
            campaign: value,
          },
        })
      }
    },
    focusMonthPicker() {
      if (this.$refs.topNavMonthPicker) {
        let picker = document.getElementsByClassName("month-picker")
        if (!picker.length) {
          this.$refs.topNavMonthPicker.focus()
        } else if (picker[0].style.display === "none") {
          this.$refs.topNavMonthPicker.focus()
        }
      }
    },
  },

  mounted() {
    this.route = this.$route
    this.month = this.route.params.month
  },
}
</script>
