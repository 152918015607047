import api from '@/api/zendesk'
import { findIndex } from 'lodash'

const namespaced = true

const state = {
  tickets: null
}

// getters
const getters = {
  tickets: state => {
    return state.tickets
  }
}

// actions
const actions = {
  refreshTickets({ commit }) {
    api.list(tickets => {
      commit('setTickets', tickets)
    })
  },
  addTicket({ commit }, payload) {
    commit('addTicket', payload);
  },
  updateTicket({ commit }, payload) {
    commit('updateTicket', payload);
  }
}

// mutations
const mutations = {
  setTickets(state, payload) {
    state.tickets = payload;
  },
  addTicket(state, payload) {
    state.tickets = [...state.tickets, payload]
  },
  updateTicket(state, payload) {
    let index = findIndex(state.tickets, { id: payload.id });
    state.tickets.splice(index, 1, payload);
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
