<script>
import { mapGetters } from "vuex"
import Moment from "moment-timezone"

export default {
  name: "DateTime",

  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
  },
  methods: {
    dateTimeFormat(value, display = "MMM Do YYYY [at] hh:mm a") {
      if (value === null) return "Never"
      let time = Moment.tz(value * 1000, "UTC").tz(this.auth.timezone)
      return time instanceof Moment && time.isValid()
        ? time.format(display)
        : ""
    },
    dateTimeFormatString(
      value,
      display = "MMM Do YYYY",
      format = "YYYY-MM-DD"
    ) {
      if (value === null) return "Never"
      let time = Moment(value, format)
      return time instanceof Moment && time.isValid()
        ? time.format(display)
        : ""
    },
    dateTimeNow(display = "YYYY") {
      let time = Moment.tz(this.auth.timezone)
      return parseInt(time.format(display))
    },
    timeZonesList() {
      return [
        {
          name: "Eastern",
          value: "America/New_York",
        },
        {
          name: "Central",
          value: "America/Chicago",
        },
        {
          name: "Mountain",
          value: "America/Denver",
        },
        {
          name: "Arizona",
          value: "America/Phoenix",
        },
        {
          name: "Pacific",
          value: "America/Los_Angeles",
        },
        {
          name: "Alaska",
          value: "America/Anchorage",
        },
        {
          name: "Aleutian",
          value: "America/Adak",
        },
        {
          name: "Hawaii",
          value: "Pacific/Honolulu",
        },
      ]
    },
  },
}
</script>
