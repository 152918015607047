import Vue from 'vue';
import App from '@/App.vue';
import { sync } from 'vuex-router-sync'
import { createRouter } from "@/router";
import store from "@/store";

import lodash from 'lodash';
import moment from 'moment';
import Moment from 'moment';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTransmit from 'vue-transmit'
import VueCooke from 'vue-cookie';
import VueObserveVisibility from 'vue-observe-visibility'
import VuePlyr from 'vue-plyr'
import VueScrollTo from 'vue-scrollto'
import '@/theme';
import '@/filters'
import '@/directives'

// Global packages.
Object.defineProperty(Vue.prototype, '$_', { value: lodash });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

// Configure Axios.
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// Configure Moment
Moment.locale('us')

// Global Vue Packages.
Vue.use(VueTransmit)
Vue.use(VueCooke)
Vue.use(VuePlyr)

Vue.use(VueScrollTo, {
  offset: -70
})

require('intersection-observer')
Vue.use(VueObserveVisibility)

// Create Store & Router before VueAuth.
Vue.store = store;
Vue.router = createRouter(Vue.store)

// Create VueAuth
Vue.use(require('@websanova/vue-auth'), {
  notFoundRedirect: '/',
  rolesVar: 'role',
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js')
})

// Build the App
App.router = Vue.router;
App.store = Vue.store;

sync(App.store, App.router)

// Global router hooks
App.router.beforeEach((to, from, next) => {
  if (to.matched[0].meta.title) {
    document.title = process.env.VUE_APP_APP_NAME + ' - ' + to.matched[0].meta.title;
  } else {
    document.title = process.env.VUE_APP_APP_NAME;
  }

  if (app) {
    app.loading = true
  }
  next()
})

App.router.afterEach((to, from) => {
  if (app) {
    app.loading = false
    app.prevRoute = from;
  }
  if (to.meta.track) {
    Vue.cookie.set('last_page', to.fullPath);
  }
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 403) {
    App.router.push('/403')
  } else if (error.response && error.response.status === 401) {
    Vue.auth().logout()
    App.router.push('/')
  } else if (error.response && error.response.status === 404) {
    App.router.push('/404')
  } else {
    return Promise.reject(error)
  }
})

// Start 'er up..
const app = new Vue(App).$mount('#app');
