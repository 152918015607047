<template>
  <data-tables
    :data="data"
    :pagination-props="paginationDef"
    :table-props="tableProps"
    :show-action-bar="false"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    class="notifications-table"
    ref="dataTable"
    v-loading="loading"
  >
    <el-row
      class="table-header"
      slot="tool"
      style="min-height: 43px"
      v-if="
        selectedUnread.length > 0 || selectedRead.length > 0 || readCount > 0
      "
    >
      <el-col class="notification-header-container">
        <div style="flex: none; white-space: nowrap">
          <el-button
            type="info"
            size="small"
            v-if="selectedUnread.length > 0"
            @click="markAsRead"
          >
            Mark as Read
          </el-button>
          <el-button
            type="info"
            size="small"
            v-if="selectedRead.length > 0"
            @click="markAsUnread"
          >
            Mark as Unread
          </el-button>
          <el-button
            type="text"
            @click="showRead = !showRead"
            v-if="readCount > 0"
          >
            {{ showRead ? "Hide" : "Show" }} Read Messages
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-table-column
      type="selection"
      width="55"
      :reserve-selection="true"
    ></el-table-column>

    <el-table-column
      prop="body"
      label="Message"
      min-width="150"
      :show-overflow-tooltip="true"
    ></el-table-column>

    <el-table-column prop="created_at" label="Date" width="200">
      <template slot-scope="scope">
        {{ dateTimeFormat(scope.row.created_at) }}
      </template>
    </el-table-column>

    <el-table-column prop="link" label="" width="50">
      <template slot-scope="scope">
        <a :href="scope.row.link">
          <font-awesome-icon icon="angle-right" full-width></font-awesome-icon>
        </a>
      </template>
    </el-table-column>
  </data-tables>
</template>

<style lang="scss">
.notification-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  max-width: 100%;
  background: #fafafa;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.notifications-table {
  tr.is-read {
    color: #aaa;
  }
}
</style>

<script>
import DateTime from "@/mixins/DateTime"

import { mapActions, mapGetters } from "vuex"

export default {
  name: "NotificationTable",

  mixins: [DateTime],

  data() {
    return {
      paginationDef: {
        pageSize: 10,
        pageSizes: [10],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "created_at",
          order: "descending",
        },
        rowKey: "id",
        rowClassName: this.getRowClass,
        emptyText: "You have no notifications.",
      },
      showRead: false,
      selectedRows: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    data() {
      if (!this.showRead) {
        return this.$_.filter(this.user.notifications, function (o) {
          return !o.read
        })
      } else {
        return this.user.notifications
      }
    },
    selectedRead() {
      return this.$_.filter(this.selectedRows, function (o) {
        return o.read
      })
    },
    selectedUnread() {
      return this.$_.filter(this.selectedRows, function (o) {
        return !o.read
      })
    },
    readAction() {
      return "auth/notifications/read"
    },
    unreadAction() {
      return "auth/notifications/unread"
    },
    readCount() {
      return this.$_.filter(this.user.notifications, function (o) {
        return o.read
      }).length
    },
  },
  watch: {
    showRead() {
      this.selectedRows = []
      this.$refs.dataTable.$refs.elTable.clearSelection()
    },
  },

  methods: {
    ...mapActions({
      updateUser: "auth/set",
    }),
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    getRowClass(row) {
      if (this.showRead) {
        if (row.row.read) {
          return "is-read"
        } else {
          return "is-unread"
        }
      }
      return ""
    },
    markAsRead() {
      if (this.selectedUnread.length > 0) {
        this.loading = true
        let data = { notifications: this.$_.map(this.selectedUnread, "id") }

        this.axios
          .post(this.readAction, data)
          .then((response) => {
            this.updateUser(response.data.data)
            this.$message.success(response.data.message)
            this.$refs.dataTable.$refs.elTable.clearSelection()
            this.loading = false
          })
          .catch((resp) => {
            this.$root.globalRequestError(resp)
            this.approvalLoading = false
          })
      }
    },
    markAsUnread() {
      if (this.selectedRead.length > 0) {
        this.loading = true
        let data = { notifications: this.$_.map(this.selectedRead, "id") }

        this.axios
          .post(this.unreadAction, data)
          .then((response) => {
            this.updateUser(response.data.data)
            this.$message.success(response.data.message)
            this.$refs.dataTable.$refs.elTable.clearSelection()
            this.loading = false
          })
          .catch((resp) => {
            this.$root.globalRequestError(resp)
            this.approvalLoading = false
          })
      }
    },
  },
}
</script>
