import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import campaigns from '@/store/modules/campaigns'
import collections from '@/store/modules/collections'
import companies from '@/store/modules/companies'
import tracker from '@/store/modules/tracker'
import users from '@/store/modules/users'
import zendesk from '@/store/modules/zendesk'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    campaigns,
    collections,
    companies,
    tracker,
    users,
    zendesk
  }
})

export default store;
