import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Home = () => import('@/pages/Home');
const Login = () => import('@/pages/Login');
const Register = () => import('@/pages/Register');
const ForgotPassword = () => import('@/pages/ForgotPassword');
const ResetPassword = () => import('@/pages/ResetPassword');
const UserAdmin = () => import('@/pages/UserAdmin');
const CollectionAdmin = () => import('@/pages/CollectionAdmin');
const CompanyAdmin = () => import('@/pages/CompanyAdmin');
const TrackerAdmin = () => import('@/pages/TrackerAdmin');
const TrackerCompanyAdmin = () => import('@/pages/TrackerCompanyAdmin');
const SupermetricsAdmin = () => import('@/pages/SupermetricsAdmin');
const User = () => import('@/pages/User');
const Company = () => import('@/pages/Company');
const Assets = () => import('@/pages/Assets');
const Month = () => import('@/pages/Month');
const Tracker = () => import('@/pages/Tracker');
const Support = () => import('@/pages/Support');

const FourOhFour = () => import('@/pages/Errors/404');
const FourOhThree = () => import('@/pages/Errors/403');

const Base = () => import('@/pages/Errors/Base');

const isMobileApp = localStorage.getItem('isMobileApp') === 'true' ? 'true' : 'false';

export function createRouter() {
  let router = new Router({
    mode: 'history',
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          auth: true,
          title: 'Home'
        }
      },
      {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
          auth: true,
          title: 'Your Settings',
          track: true
        }
      },
      {
        path: '/admin/users',
        name: 'admin_users',
        component: UserAdmin,
        meta: {
          auth: {
            roles: ['super', 'admin']
          },
          title: 'User Administration',
          track: true
        }
      },
      {
        path: '/admin/companies',
        name: 'admin_companies',
        component: CompanyAdmin,
        meta: {
          auth: {
            roles: ['super']
          },
          title: 'Company Administration',
          track: true
        }
      },
      {
        path: '/admin/collections',
        name: 'admin_collections',
        component: CollectionAdmin,
        meta: {
          auth: {
            roles: ['super']
          },
          title: 'Collection Administration',
          track: true
        }
      },
      {
        path: '/admin/tracker',
        name: 'admin_tracker',
        component: TrackerAdmin,
        meta: {
          auth: {
            roles: ['super']
          },
          title: 'Tracker Administration'
        }
      },
      {
        path: '/admin/tracker/:company',
        name: 'admin_tracker_company',
        component: TrackerCompanyAdmin,
        meta: {
          auth: {
            roles: ['super']
          }
        }
      },
      {
        path: '/admin/supermetrics',
        name: 'admin_supermetrics',
        component: SupermetricsAdmin,
        meta: {
          auth: {
            roles: ['super']
          }
        }
      },
      {
        path: '/client/:company',
        name: 'company',
        component: Company,
        meta: {
          auth: true,
          middleware: ['company'],
          track: true
        }
      },
      {
        path: '/client/:company/assets',
        name: 'assets',
        component: Assets,
        meta: {
          auth: true,
          middleware: ['company'],
          track: true
        }
      },
      {
        path: '/client/:company/tracker/:type?/:service?',
        name: 'tracker',
        component: Tracker,
        meta: {
          auth: true
        }
      },
      {
        path: '/client/:company/assets/:month/:campaign?/:collection?',
        name: 'month',
        component: Month,
        meta: {
          auth: true,
          track: true
        }
      },
      {
        path: '/client/:company/:month/:campaign?/:collection?',
        name: 'month-assets',
        component: Month,
        meta: {
          auth: true,
          track: true
        }
      },
      {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
          auth: true,
          title: 'Support',
          track: true
        }
      },
      {
        path: '/client/:company/support',
        name: 'company_support',
        component: Support,
        meta: {
          auth: true,
          middleware: ['company'],
          title: 'Support',
          track: true
        }
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          auth: false
        }
      },
      {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
          auth: false
        }
      },
      {
        path: '/forgot_password',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
          auth: false
        }
      },
      {
        path: '/reset_password',
        name: 'reset_password',
        component: ResetPassword,
        meta: {
          auth: false
        }
      },
      {
        path: '/403',
        name: '403',
        component: isMobileApp ? Base : FourOhThree,
        meta: {
          title: 'Forbidden'
        }
      },
      {
        path: '*',
        name: '404',
        component: isMobileApp ? Base : FourOhFour,
        meta: {
          title: 'Page Not Found'
        }
      },
    ]
  })

  return router;
}
