import App from '@/App.vue'
import axios from 'axios'

export default {
  list(cb) {
    axios.get('zendesk/tickets/list')
      .then(response => cb(response.data.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  },
  single(id, cb) {
    axios.get('zendesk/tickets/comments/' + id)
      .then(response => cb(response.data.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
