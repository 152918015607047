import api from '@/api/campaigns'
import { findIndex } from 'lodash'
import store from '@/store'

const namespaced = true

const state = {
  campaigns: null
}

// getters
const getters = {
  list: state => {
    return state.campaigns
  },
  totalItems: state => state.total_items,
  services: state => state.services
}

// actions
const actions = {
  refresh({ commit }, { page = 1, query = '', service = '' }) {
    api.trackerListCampaigns(store.state.route.params.company, page, service, query,
      data => {
        commit('setCampaigns', data.campaigns)
        commit('setTotalItems', data.total_items)

      })
  },
  servicesList({ commit }) {
    return new Promise((resolve, reject) => {
      api.trackerListServices(store.state.route.params.company,
        data => {
          commit('setServices', data)
          resolve(data)
        },
        error => reject(error)
      )
    })
  },
  set({ commit }, payload) {
    commit('setCampaigns', payload);
  },
  add({ commit }, payload) {
    commit('addCampaign', payload);
  },
  update({ commit }, payload) {
    commit('updateCampaign', payload);
  },
  remove({ commit }, payload) {
    commit('removeCampaign', payload);
  },
  addAsset({ commit }, payload) {
    commit('addAsset', payload);
  },
  updateAsset({ commit }, payload) {
    commit('updateAsset', payload);
  },
  removeAsset({ commit }, payload) {
    commit('removeAsset', payload);
  },
  addFile({ commit }, payload) {
    commit('addFile', payload);
  },
  updateFile({ commit }, payload) {
    commit('updateFile', payload);
  },
  removeFile({ commit }, payload) {
    commit('removeFile', payload);
  },
}

// mutations
const mutations = {
  setCampaigns(state, payload) {
    state.campaigns = payload;
  },
  addCampaign(state, payload) {
    state.campaigns = [...state.campaigns, payload]
  },
  updateCampaign(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.slug });
    state.campaigns.splice(campaign, 1, payload);
  },
  removeCampaign(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.slug });
    state.campaigns.splice(campaign, 1);
  },
  addAsset(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    if (collection === -1) {
      collection = (state.campaigns[campaign].collections.push({
        assets: [],
        type: payload.collection,
      })) - 1;
    }
    state.campaigns[campaign].collections[collection].assets = [...state.campaigns[campaign].collections[collection].assets, payload.data]
  },
  updateAsset(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    let asset = findIndex(state.campaigns[campaign].collections[collection].assets, { slug: payload.data.slug });
    state.campaigns[campaign].collections[collection].assets.splice(asset, 1, payload.data);
  },
  removeAsset(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    let asset = findIndex(state.campaigns[campaign].collections[collection].assets, { slug: payload.data });
    state.campaigns[campaign].collections[collection].assets.splice(asset, 1);
    if (state.campaigns[campaign].collections[collection].assets.length === 0) {
      state.campaigns[campaign].collections.splice(collection, 1);
    }
  },
  addFile(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    let asset = findIndex(state.campaigns[campaign].collections[collection].assets, { slug: payload.asset });
    state.campaigns[campaign].collections[collection].assets[asset].files = [...state.campaigns[campaign].collections[collection].assets[asset].files, payload.data]
  },
  updateFile(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    let asset = findIndex(state.campaigns[campaign].collections[collection].assets, { slug: payload.asset });
    let file = findIndex(state.campaigns[campaign].collections[collection].assets[asset].files, { id: payload.data.id });
    state.campaigns[campaign].collections[collection].assets[asset].files.splice(file, 1, payload.data);
  },
  removeFile(state, payload) {
    let campaign = findIndex(state.campaigns, { slug: payload.campaign });
    let collection = findIndex(state.campaigns[campaign].collections, { type: payload.collection });
    let asset = findIndex(state.campaigns[campaign].collections[collection].assets, { slug: payload.asset });
    let file = findIndex(state.campaigns[campaign].collections[collection].assets[asset].files, { id: payload.data });
    state.campaigns[campaign].collections[collection].assets[asset].files.splice(file, 1);
  },
  setTotalItems(state, total) {
    state.total_items = total;
  },
  setServices(state, payload) {
    state.services = payload;
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
