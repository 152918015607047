<script>
import { mapGetters } from "vuex"

export default {
  name: "Whitelabel",

  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    primary() {
      return this.company && this.company.color
        ? this.company.color
        : this.$root.app.primaryColor
    },
    themeClusters() {
      let theme = this.primary.replace("#", "")
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        if (tint === 0) {
          return [red, green, blue].join(",")
        } else {
          red += Math.round(tint * (255 - red))
          green += Math.round(tint * (255 - green))
          blue += Math.round(tint * (255 - blue))
          red = red.toString(16)
          green = green.toString(16)
          blue = blue.toString(16)
          return `#${red}${green}${blue}`
        }
      }
      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        red = Math.round((1 - shade) * red)
        green = Math.round((1 - shade) * green)
        blue = Math.round((1 - shade) * blue)
        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)
        return `#${red}${green}${blue}`
      }
      const clusters = [theme]
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
      }
      clusters.push(shadeColor(theme, 0.1))
      return clusters
    },
  },

  methods: {
    applyStyles() {
      let head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style")

      let styles = `
          .el-button--primary.is-plain:hover,
          .el-button--primary.is-plain:focus,
          .el-radio__input.is-checked .el-radio__inner,
          .el-button.is-plain:hover,
          .el-button.is-plain:focus,
          .audio-container .plyr--video .plyr__control.plyr__tab-focus,
          .audio-container .plyr--video .plyr__control:hover,
          .audio-container .plyr--video .plyr__control[aria-expanded=true],
          .audio-container .plyr__control--overlaid:focus,
          .audio-container .plyr__control--overlaid:hover,
          .audio-container .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before
          {
            background: ${this.primary} ;
          }

          .el-button--primary,
          .el-radio-button__orig-radio:checked + .el-radio-button__inner,
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner,
          .el-checkbox-button.is-checked .el-checkbox-button__inner,
          .el-switch.is-checked .el-switch__core,
          .el-table-filter__list-item.is-active,
          .el-tabs__active-bar,
          .el-tree__drop-indicator,
          .el-tree-node.is-drop-inner > .el-tree-node__content .el-tree-node__label,
          .el-slider__bar,
          .el-progress-bar__inner,
          .el-transfer__button,
          .audio-container .info .progress .el-progress-bar__inner,
          .audio-container .plyr--audio .plyr__control.plyr__tab-focus,
          .audio-container .plyr--audio .plyr__control:hover,
          .audio-container .plyr--audio .plyr__control[aria-expanded=true]
          {
            background-color: ${this.primary} ;
          }

          .el-input__inner:focus,
          .el-input.is-active .el-input__inner,
          .el-select .el-input__inner:focus,
          .el-select .el-input.is-focus .el-input__inner,
          .el-pagination__sizes .el-input .el-input__inner:hover,
          .el-textarea__inner:focus,
          .el-input__inner:focus,
          .el-button.is-plain:hover,
          .el-button.is-plain:focus,
          .el-button--primary,
          .el-button--primary.is-plain:hover,
          .el-button--primary.is-plain:focus,
          .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
          .el-menu--horizontal > .el-menu-item.is-active,
          .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
          .el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
          .el-radio.is-bordered.is-checked,
          .el-radio__input.is-checked .el-radio__inner,
          .el-radio__input.is-focus .el-radio__inner,
          .el-radio__inner:hover,
          .el-radio-button__orig-radio:checked + .el-radio-button__inner,
          .el-checkbox.is-bordered.is-checked,
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-focus .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner,
          .el-checkbox__inner:hover,
          .el-checkbox-button.is-checked .el-checkbox-button__inner,
          .el-checkbox-button.is-focus .el-checkbox-button__inner,
          .el-switch.is-checked .el-switch__core,
          .el-date-table td.start-date span,
          .el-date-table td.end-date span,
          .el-range-editor.is-active,
          .el-range-editor.is-active:hover,
          .el-slider__button,
          .el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__decrease,
          .el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__increase,
          .el-upload--picture-card:hover,
          .el-upload:focus,
          .el-upload:focus .el-upload-dragger,
          .el-upload-dragger:hover,
          .el-upload-dragger.is-dragover,
          .el-step__head.is-finish,
          .el-color-dropdown__btn:hover,
          .top-nav .nav-container .main-container .right-container .avatar-wrapper .avatar
          {
            border-color: ${this.primary} ;
          }

          .el-submenu.is-active .el-submenu__title,
          .asset-admin-container .asset-admin-header .asset-admin-header-name-container .asset-admin-header-name,
          .asset-view-container .asset-header .asset-controls,
          .asset-view-container .mixed-media-header span,
          .user-edit-heading span,
          .table-header .table-header-name span,
          .ticket-header .ticket-header-wrapper .ticket-header-name span,
          .tracker-settings-header .tracker-settings-header-name span
          {
            border-bottom-color: ${this.primary} ;
          }

          .el-checkbox-button.is-checked:first-child .el-checkbox-button__inner,
          .campaign-container .campaign-header,
          .campaign-container .no-assets,
          .collection-container .collection-header
          {
            border-left-color: ${this.primary} ;
          }

          .el-select-dropdown.is-multiple .el-select-dropdown__item.selected,
          .el-select-dropdown__item.selected,
          .el-pagination button:hover,
          .el-pagination.is-background .el-pager li:not(.disabled):hover,
          .el-pagination.is-background .el-pager li:not(.disabled).active,
          .el-pager li:hover,
          .el-pager li.active,
          .el-dialog__headerbtn:focus .el-dialog__close,
          .el-dialog__headerbtn:hover .el-dialog__close,
          .el-button--primary.is-plain,
          .el-button--text,
          .el-menu-item.is-active,
          .el-input-number__increase:hover,
          .el-input-number__decrease:hover,
          .el-radio__input.is-checked + .el-radio__label,
          .el-radio-button__inner:hover,
          .el-checkbox__input.is-checked + .el-checkbox__label,
          .el-checkbox-button__inner:hover,
          .el-switch__label.is-active,
          .el-select-dropdown.is-multiple .el-select-dropdown__item.selected,
          .el-table th > .cell.highlight,
          .el-table .ascending .sort-caret.ascending,
          .el-table .descending .sort-caret.descending,
          .el-table-filter__bottom button:hover,
          .el-date-table td.today span,
          .el-date-table td.available:hover,
          .el-date-table td.current:not(.disabled) span,
          .el-month-table td .cell:hover,
          .el-month-table td.current:not(.disabled) .cell,
          .evergreen-option.current,
          .el-year-table td .cell:hover,
          .el-year-table td.current:not(.disabled) .cell,
          .el-time-spinner__arrow:hover,
          .el-picker-panel__shortcut:hover,
          .el-picker-panel__shortcut.active,
          .el-picker-panel__icon-btn:hover,
          .el-date-picker__header-label:hover,
          .el-date-picker__header-label.active,
          .el-time-panel__btn.confirm,
          .el-message-box__headerbtn:focus .el-message-box__close,
          .el-message-box__headerbtn:hover .el-message-box__close,
          .el-breadcrumb__inner.is-link:hover,
          .el-breadcrumb__inner a:hover,
          .el-tabs__new-tab:hover,
          .el-tabs__item.is-active,
          .el-tabs__item:hover,
          .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
          .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover,
          .el-loading-spinner .el-loading-text,
          .el-loading-spinner i,
          .el-upload--picture-card:hover,
          .el-upload:focus,
          .el-upload-dragger .el-upload__text em,
          .el-upload-list__item .el-icon-close-tip,
          .el-upload-list__item.is-success .el-upload-list__item-name:hover,
          .el-upload-list__item.is-success .el-upload-list__item-name:focus,
          .el-upload-list__item-delete:hover,
          .el-step__head.is-finish,
          .el-step__title.is-finish,
          .el-step__description.is-finish,
          .el-collapse-item__header.focusing:focus:not(:hover),
          .el-cascader-menu__item.is-active,
          .el-color-dropdown__btn:hover,
          .el-color-dropdown__link-btn,
          .el-transfer-panel__item:hover,
          a,
          .text-primary,
          .el-button--text svg,
          .svg-inline--fa.is-primary,
          .month-container .month .month-campaign:hover,
          .month-container .month:not(.empty-month) .month-name:hover,
          .year-arrow.active,
          .new-dropdown,
          .el-input-group__append button.el-button,
          .el-input-group__append div.el-select .el-input__inner,
          .el-input-group__append div.el-select:hover .el-input__inner,
          .el-input-group__prepend button.el-button,
          .el-input-group__prepend div.el-select .el-input__inner,
          .el-input-group__prepend div.el-select:hover .el-input__inner,
          .mint-datetime-action,
          .audio-container .plyr--full-ui input[type=range]
          {
            color: ${this.primary} ;
          }

          .el-color-dropdown__link-btn:hover {
            color: tint(${this.primary}, 20%); }
          }

          .el-radio:focus:not(.is-focus):not(:active) .el-radio__inner,
          .el-radio-button:focus:not(.is-focus):not(:active)
          {
            -webkit-box-shadow: 0 0 2px 2px ${this.primary} ;
            box-shadow: 0 0 2px 2px  ${this.primary} ;
          }

          .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            -webkit-box-shadow: -1px 0 0 0 ${this.primary} ;
            box-shadow: -1px 0 0 0 ${this.primary} ;
          }

          .el-color-predefine__color-selector.selected{
            -webkit-box-shadow: 0 0 3px 2px ${this.primary} ;
            box-shadow: 0 0 3px 2px ${this.primary} ;
          }

          .el-switch__input:focus ~ .el-switch__core {
            outline: 1px solid ${this.primary} ;
          }

          .top-nav .nav-container .logo-container .nav-logo,
          .el-button--text svg
          {
            fill: ${this.primary} ;
          }

          .el-loading-spinner .path {
            stroke: ${this.primary} ;
          }
        `

      if (this.themeClusters) {
        styles += `
            .el-button--primary:hover, .el-button--primary:focus
            {
              background: ${this.themeClusters[3]} ;
            }

            .el-button--primary:hover, .el-button--primary:focus
            {
              border-color: ${this.themeClusters[3]} ;
            }

            .el-button--text:hover,
            .el-button--text:focus,
            .el-dropdown-menu__item:not(.is-disabled):hover,
            .el-dropdown-menu__item:focus,
            .el-table-filter__list-item:hover
            {
              color: ${this.themeClusters[3]} ;
            }
        `

        styles += `
          .el-button--primary.is-plain.is-disabled,
          .el-button--primary.is-plain.is-disabled:hover,
          .el-button--primary.is-plain.is-disabled:focus,
          .el-button--primary.is-plain.is-disabled:active
          {
            color: ${this.themeClusters[5]} ;
          }

          .el-checkbox-button.is-checked .el-checkbox-button__inner
          {
            -webkit-box-shadow: -1px 0 0 0 ${this.themeClusters[5]} ;
            box-shadow: -1px 0 0 0 ${this.themeClusters[5]} ;
          }
        `

        styles += `
          .el-button--primary.is-disabled,
          .el-button--primary.is-disabled:hover,
          .el-button--primary.is-disabled:focus,
          .el-button--primary.is-disabled:active
          {
            background-color: ${this.themeClusters[6]} ;
          }

          .el-button--primary.is-disabled,
          .el-button--primary.is-disabled:hover,
          .el-button--primary.is-disabled:focus,
          .el-button--primary.is-disabled:active
          {
            border-color: ${this.themeClusters[6]} ;
          }
        `

        styles += `
          .el-button--primary.is-plain
          {
            border-color: ${this.themeClusters[7]} ;
          }
        `

        styles += `
          .el-button--primary.is-plain.is-disabled,
          .el-button--primary.is-plain.is-disabled:hover,
          .el-button--primary.is-plain.is-disabled:focus,
          .el-button--primary.is-plain.is-disabled:active
          {
            border-color: ${this.themeClusters[9]} ;
          }
        `

        styles += `
          .el-button--primary.is-plain.is-disabled,
          .el-button--primary.is-plain.is-disabled:hover,
          .el-button--primary.is-plain.is-disabled:focus,
          .el-button--primary.is-plain.is-disabled:active,
          .el-dropdown-menu__item:not(.is-disabled):hover,
          .el-dropdown-menu__item:focus,
          .el-menu-item:hover,
          .el-menu-item:focus,
          .el-submenu__title:hover,
          .el-submenu__title:focus,
          .el-submenu__title:hover,
          .el-table--striped .el-table__body tr.el-table__row--striped.current-row td,
          .el-table__body tr.hover-row > td, .el-table__body tr.hover-row.current-row > td,
          .el-table__body tr.hover-row.el-table__row--striped > td,
          .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
          .el-table__body tr.current-row > td,
          .el-table-filter__list-item:hover
          {
            background-color: ${this.themeClusters[10]} ;
          }

          .el-button--primary.is-plain
          {
            background: ${this.themeClusters[10]} ;
          }
        `

        styles += `
          .el-button--primary:active,
          .el-button--primary.is-active,
          .el-button--primary.is-plain:active
          {
            background: ${this.themeClusters[4]} ;
          }

          .el-button:active,
          .el-button.is-plain:active,
          .el-button.is-active,
          .el-button--primary:active,
          .el-button--primary.is-active,
          .el-button--primary.is-plain:active
          {
            border-color: ${this.themeClusters[11]} ;
          }

          .el-button:active,
          .el-button.is-plain:active,
          .el-button.is-active,
          .el-button--text:active
          {
            color: ${this.themeClusters[10]} ;
          }
        `

        styles += `
          .el-button--text,
          .el-button--text:hover,
          .el-button--text:active,
          .el-button--text:focus
          {
            background: transparent ;
            border-color: transparent ;
          }
        `
      }

      style.type = "text/css"
      if (style.styleSheet) {
        style.styleSheet.cssText = styles
      } else {
        style.appendChild(document.createTextNode(styles))
      }
      style.setAttribute("data-whitelabel", "")

      head.appendChild(style)
    },

    destroyExistingStyles() {
      let el = document.querySelector("[data-whitelabel]")
      if (el) {
        el.parentNode.removeChild(el)
      }
    },
  },
}
</script>
