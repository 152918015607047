<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Open a Support Request</span>
      <el-button type="text" @click="closeModal">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="create"
      label-position="right"
      label-width="100px"
    >
      <el-form-item
        :error="
          form.errors.first('subject') ? form.errors.first('subject') : null
        "
        label="Subject"
        required
      >
        <el-input
          v-model="ticket.subject"
          type="text"
          placeholder="Subject"
          @focus="form.errors.clear('subject')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('body') ? form.errors.first('body') : null"
        label="Body"
        required
      >
        <el-input
          type="textarea"
          :rows="3"
          v-model="ticket.body"
          @focus="form.errors.clear('body')"
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('company') ? form.errors.first('company') : null
        "
        label="Company"
        required
        v-if="multiCompany && !scopedToCompany"
      >
        <modal-select>
          <el-select
            v-model="ticket.company"
            :style="{ width: '100%' }"
            clearable
            @focus="form.errors.clear('company')"
            :disabled="includeFollowUp"
            v-if="company.zendesk_id"
          >
            <el-option
              v-for="company in user.companies"
              :key="company.slug"
              :value="company.slug"
              :label="company.name"
            >
            </el-option>
            <el-option
              v-if="$auth.check(['admin', 'super', 'employee'])"
              key="internal"
              value="internal"
              :label="$root.app.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="ticket.company"
            :style="{ width: '100%' }"
            clearable
            @focus="form.errors.clear('company')"
            :disabled="includeFollowUp"
            v-else
          >
            <el-option
              v-if="$auth.check(['admin', 'super', 'employee'])"
              key="internal"
              value="internal"
              :label="$root.app.name"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-row>
        <el-col :md="12">
          <el-form-item
            :error="
              form.errors.first('priority')
                ? form.errors.first('priority')
                : null
            "
            label="Priority"
            required
          >
            <modal-select>
              <el-select
                v-model="ticket.priority"
                :style="{ width: '100%' }"
                clearable
                @focus="form.errors.clear('priority')"
              >
                <el-option label="Low" value="low"></el-option>
                <el-option label="Normal" value="normal"></el-option>
                <el-option label="High" value="high"></el-option>
                <el-option label="Urgent" value="urgent"></el-option>
              </el-select>
            </modal-select>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            :error="
              form.errors.first('due_date')
                ? form.errors.first('due_date')
                : null
            "
            label="Due Date"
          >
            <el-date-picker
              v-model="ticket.due_date"
              type="date"
              align="center"
              format="M/d/yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              clearable
              :picker-options="pickerOptions"
              @focus="form.errors.clear('due_date')"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-upload
          class="attachment-uploader"
          drag
          action="#"
          :http-request="uploadAttachment"
          :file-list="attachments"
          :before-upload="beforeUpload"
          :before-remove="beforeRemove"
          multiple
        >
          <div class="el-upload__text">
            Drop file here or <em>click to attach</em>
            <br />
            <small>Attachments must be less than 20 MB.</small>
          </div>
        </el-upload>
      </el-row>

      <el-row class="follow-up-alert" v-if="follow_up && includeFollowUp">
        This ticket is being created as a follow up to
        <em>{{ follow_up.subject }}</em> created by
        {{ follow_up.requester.name }} on
        {{ dateTimeFormat(follow_up.created_at, "M/D/YY") }}.
        <a
          class="has-cursor-pointer"
          style="float: right"
          @click="revertFollowUp"
        >
          Revert
        </a>
      </el-row>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>

<style lang="scss">
.attachment-uploader {
  margin-bottom: 1rem;
  .el-upload {
    width: 100%;
    max-height: 50px;
    .el-upload-dragger {
      width: 100%;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.follow-up-alert {
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 0.75rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
</style>

<script>
import DateTime from "@/mixins/DateTime"

import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SupportTicketCreateForm",

  mixins: [DateTime],

  props: {
    follow_up: {
      default: null,
    },
  },
  data() {
    return {
      ticket: {
        subject: "",
        body: "",
        type: "",
        priority: "normal",
        due_date: "",
        company: "",
        attached_tokens: {},
      },
      form: new Form(),
      loading: false,
      pickerOptions: {
        disabledDate: (date) => {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        firstDayOfWeek: 7,
      },
      attachments: [],
      includeFollowUp: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    multiCompany() {
      return (
        (this.$auth.check(["client", "approval-client"]) &&
          this.user.companies.length > 1) ||
        (!this.$auth.check(["client", "approval-client"]) &&
          this.user.companies.length > 0)
      )
    },
    scopedToCompany() {
      return this.$route.name === "company_support"
    },
  },

  methods: {
    ...mapActions({
      addTicket: "zendesk/addTicket",
    }),
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20

      if (!isLt20M) {
        this.$message.warning("Attachments can not exceed 20MB!")
      }
      return isLt20M
    },
    beforeRemove(file) {
      delete this.ticket.attached_tokens[file.uid]
    },
    uploadAttachment(data) {
      this.loading = true

      let formData = new FormData()
      formData.append("file", data.file)

      this.axios
        .post("zendesk/tickets/attachment", formData)
        .then((response) => {
          this.loading = false
          this.ticket.attached_tokens[data.file.uid] = response.data.data
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    closeModal() {
      this.$modal.hide("create-support-ticket")
      this.$modal.hide("create-support-ticket-dynamic")
    },
    create() {
      this.loading = true

      let data = this.$_.cloneDeep(this.ticket)
      data.attached_tokens = Object.values(data.attached_tokens)
      if (this.follow_up && this.includeFollowUp) {
        data.follow_up = this.follow_up.id
      }

      if (!this.multiCompany) {
        if (this.user.companies.length) {
          data.company = this.user.companies[0].slug
        } else {
          data.company = "internal"
        }
      }

      if (this.scopedToCompany) {
        data.company = this.$route.params.company
      }

      this.axios
        .post("zendesk/tickets/create", data)
        .then((response) => {
          this.addTicket(response.data.data)
          this.closeModal()
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    revertFollowUp() {
      this.includeFollowUp = false
      this.ticket.company = ""
    },
  },

  created() {
    if (this.follow_up) {
      this.ticket.company = this.follow_up.company.slug
      this.includeFollowUp = true
    }
  },
}
</script>
