import App from '@/App.vue'
import axios from 'axios'

export default {
  getCompanyAdmin(cb, slug) {
    axios.get('/tracker/' + slug)
      .then(response => cb(response.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
