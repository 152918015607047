var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(!_vm.$auth.ready() || _vm.$root.loading),expression:"!$auth.ready() || $root.loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"is-vertical main"},[(
      ['404', '403'].indexOf(this.$route.name) === -1 &&
      _vm.$auth.ready() &&
      _vm.$auth.check()
    )?_c('top-nav'):_vm._e(),_c('el-main',{style:(_vm.containerStyles)},[(_vm.$auth.ready())?[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)]:_vm._e()],2),(
      ['404', '403'].indexOf(this.$route.name) === -1 &&
      _vm.$auth.ready() &&
      _vm.$auth.check() &&
      !_vm.isMobileApp
    )?_c('el-footer',[_c('el-row',[_c('el-col',{staticStyle:{"height":"60px","display":"flex","align-items":"center","justify-content":"center"}},[_c('p',{staticStyle:{"margin":"0","text-align":"center"}},[_vm._v(" Copyright 2017 - "+_vm._s(_vm.dateTimeNow())+" "+_vm._s(_vm.app.name)),_c('br'),_c('small',[_vm._v("All other product names, logos, and brands are property of their respective owners. ")])])])],1)],1):_vm._e(),_c('modals-container'),_c('modal',{attrs:{"name":"create-support-ticket","adaptive":"","height":"auto","scrollable":"","width":"1000"}},[_c('support-ticket-create-form')],1),_c('resize-observer',{on:{"notify":_vm.handleResize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }