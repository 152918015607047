<template>
  <div>
    <div class="el-picker-panel__body">
      <!---->
      <div class="el-date-picker__header el-date-picker__header--bordered">
        <button
          type="button"
          aria-label="Previous Year"
          class="
            el-picker-panel__icon-btn
            el-date-picker__prev-btn
            el-icon-d-arrow-left
          "
          @click="subYear"
        ></button>
        <span role="button" class="el-date-picker__header-label">{{
          currentYear
        }}</span>
        <button
          type="button"
          aria-label="Next Year"
          class="
            el-picker-panel__icon-btn
            el-date-picker__next-btn
            el-icon-d-arrow-right
          "
          @click="addYear"
        ></button>
      </div>
      <div class="el-picker-panel__content">
        <div
          class="evergreen-option"
          :class="{
            current: this.month === 'evergreen',
            disabled: !this.evergreenEnabled,
          }"
          @click="change('evergreen')"
        >
          Evergreen
        </div>
        <table class="el-month-table">
          <tbody>
            <tr>
              <td :class="tdClass(1)" @click="change(1)">
                <a class="cell">Jan</a>
              </td>
              <td :class="tdClass(2)" @click="change(2)">
                <a class="cell">Feb</a>
              </td>
              <td :class="tdClass(3)" @click="change(3)">
                <a class="cell">Mar</a>
              </td>
              <td :class="tdClass(4)" @click="change(4)">
                <a class="cell">Apr</a>
              </td>
            </tr>
            <tr>
              <td :class="tdClass(5)" @click="change(5)">
                <a class="cell">May</a>
              </td>
              <td :class="tdClass(6)" @click="change(6)">
                <a class="cell">Jun</a>
              </td>
              <td :class="tdClass(7)" @click="change(7)">
                <a class="cell">Jul</a>
              </td>
              <td :class="tdClass(8)" @click="change(8)">
                <a class="cell">Aug</a>
              </td>
            </tr>
            <tr>
              <td :class="tdClass(9)" @click="change(9)">
                <a class="cell">Sep</a>
              </td>
              <td :class="tdClass(10)" @click="change(10)">
                <a class="cell">Oct</a>
              </td>
              <td :class="tdClass(11)" @click="change(11)">
                <a class="cell">Nov</a>
              </td>
              <td :class="tdClass(12)" @click="change(12)">
                <a class="cell">Dec</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbPicker",
  props: {
    existing: {
      required: true,
    },
    company: {
      required: true,
    },
  },
  data() {
    return {
      month: this.existing,
      currentYear: null,
    }
  },
  computed: {
    existingYear() {
      if (this.month === "evergreen") return null
      return parseInt(this.existing.split("-")[1])
    },
    existingMonth() {
      if (this.month === "evergreen") return null
      return parseInt(this.existing.split("-")[0])
    },
    evergreenEnabled() {
      if (this.$auth.check(["super", "admin", "employee"])) return true
      return (
        this.$_.find(this.company.months, (o) => o === "evergreen") !==
        undefined
      )
    },
  },
  methods: {
    subYear() {
      this.currentYear = this.currentYear - 1
    },
    addYear() {
      this.currentYear = this.currentYear + 1
    },
    tdClass(month) {
      let tdClass = ""
      if (
        month === this.existingMonth &&
        this.currentYear === this.existingYear
      ) {
        tdClass += "current "
      }
      if (
        month === this.$moment().month() + 1 &&
        this.currentYear === this.$moment().year()
      ) {
        tdClass += "today "
      }
      if (month < 10) {
        month = "0" + month
      }
      if (this.disabledDate(month + "-" + this.currentYear)) {
        tdClass += "disabled "
      }
      return tdClass
    },
    disabledDate: function (date) {
      if (this.$auth.check(["super", "admin", "employee"])) return false
      return this.$_.find(this.company.months, (o) => o === date) === undefined
    },
    change(month) {
      let truthy = false
      if (month !== "evergreen") {
        if (month < 10) {
          month = "0" + month
        }
        month = month + "-" + this.currentYear
        truthy = !this.disabledDate(month)
      } else {
        if (this.evergreenEnabled) truthy = true
      }

      return truthy ? this.$emit("input", month) : null
    },
  },
  created() {
    if (this.existing !== "evergreen") {
      let split = this.existing.split("-")
      this.currentYear = parseInt(split[1])
    } else {
      this.currentYear = this.$moment().year()
    }
  },
}
</script>

<style scoped lang="scss">
.evergreen-option {
  width: 100%;
  text-align: center;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  background: rgba(103, 194, 58, 0.1);
  border-radius: 0.25rem;
  cursor: pointer;
}

.evergreen-option.current {
  background: rgba(103, 194, 58, 0.25);
}

.evergreen-option.disabled {
  background: #c0c4cc;
  cursor: not-allowed;
}

.el-month-table td {
  padding: 5px 3px;
}

/*.el-month-table td.current {*/
/*background: rgba(0,0,0,.1);*/
/*border-radius: .25rem;*/
/*}*/
</style>
